import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  financingAvailable: false,
};

const vehicleSlice = createSlice({
  initialState,
  name: 'vehicle',
  reducers: {
    setFinancingAvailable: (state, action) => {
      state.financingAvailable = action.payload;
    },
  },
});

const vehicle = vehicleSlice.reducer;

export default vehicle;
export const { setFinancingAvailable } = vehicleSlice.actions;
